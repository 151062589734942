#root {
  height: 100%;
}

#login-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

#WelcomeBack {
  font-family: Inter;
  font-style: normal;
  flex: 1;
  font-size: 32px;
  color: #000000;
  font-weight: 400;
  height: 44px;
  display: flex;
  align-items: center;
}

#information {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

#username {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #75848B;
}

.help {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #318FCE;
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.help:hover {
  color: #2977AC;
}

.help:active {
  color: #215F89;
}

.submit-row {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.loginView__loginArea__bottom {
  text-align: center;
}

.loginView__loginArea__bottom {
  margin-bottom: 64px;
}

.Back {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.TeamList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.TeamList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  background-color: rgba(117, 132, 139, 0.5);
}

.TeamList::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  padding-left: 8px;
}

.TeamList {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-height: calc(100vh - 120px - 200px);
  padding: 1px 0;
  overflow-y: auto;
}

.Team {
  position: relative;
  border: 1px solid #C3CFD5;
  box-sizing: border-box;
  margin-top: -1px;
  padding: 16px;
  -webkit-border-radius:8px;
  -moz-border-radius:8px;
  border-radius:8px;
  cursor: pointer;
}

.Team:hover {
  background: #F2F2F2;
}

.Go {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  display: block;
  transform: translateY(-50%);
}

.TeamName {
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 20px;
  color: #0B0C0D;
  padding-bottom: 4px;
}

.TeamAddress {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #0B0C0D;
}

.TeamAddress span {
  color: #98A4AA;
}