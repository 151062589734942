/* Reset  */
* { margin: 0; padding: 0; }
*, *:before, *:after { box-sizing: border-box; -webkit-box-sizing: border-box;}
html { height: 100%; overflow-y: scroll;}
body{ height:100%; margin:0 auto;}
ul { list-style: none;}
h1, h2, h3, h4, h5, h6 { font-weight: normal; } 
a, img, a img { outline: none; border:none; }
a { text-decoration: none; cursor: pointer; } 
iframe{ border: none; }

body,
html {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 12px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-font-kerning: auto;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern";
    font-kerning: normal;
}

a {
    color: #067DB8;
    text-decoration: none;
    cursor: pointer;
}

#login-container, #unsubscribe-container {
    position: relative
}

#login-container, 
#unsubscribe-container,
.loginView {
    height: 100%
}

#unsubscribe-content {
    text-align: center;
}

.referral{
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-align: center;
}

.referral .referral_vector img {
    max-width: 304px;
}

.referral_content {
    font-family: 'Inter', sans-serif;

}

.referral_content p{
    padding: 16px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3B484F;
}

.referral_content a{
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #3399CC;
}

.referral_content h2{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.loginView__loginArea {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    padding: 40px 20px 0;
    position: relative;
}

.loginView__parim-logo {
    position: absolute;
    top: 64px;
    width: 100%;
    text-align: center;
}

.loginView__parim-logo img {
    height: 29px;
}

.loginView__loginArea,
.loginView__loginArea__wp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: hidden;
}

.loginView__loginArea__wp {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.loginView__sideArea {
    display: none;
}

.loginView__loginArea__fm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: center;
    padding-bottom: 120px;
    max-height: calc(100% - 120px);
}

.loginView__loginArea__top {
    display: block;
    height: 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.loginForm .logo{
    margin-bottom: 10px;
}

@media (min-height: 600px) {
    .loginView__loginArea__top {
        height: 70px;
        margin-bottom: 60px;
    }
    .loginForm .logo{
        margin-bottom: 30px;
    }
}

@media (min-width:768px) {
    .loginView {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .loginView__loginArea {
        padding: 0
    }
    .loginView__loginArea__fm {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0
    }
    .loginView__loginArea__fm,
    .loginView__loginArea__wp {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        justify-content: center;
    }
    .loginView__loginArea__wp {
        width: 740px;
        flex-direction: column;
        padding: 64px 60px
    }
}

@media (min-width:1024px) {
    .loginView__loginArea {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%;
        overflow-y: auto
    }
    .loginView__sideArea {
        position: relative;
        display: block;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%
    }
    .loginView__loginArea__bottom {
        height: 70px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    button[type="submit"] {
      max-width: 216px;
      height: 44px;
    }
}

@media (min-width:1440px) {
    .loginView__loginArea {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%
    }
    .loginView__sideArea {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%
    }
}



/*rightside*/

.loginView__sideArea__wp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #EDF3F7;
    /* background: #318FCE; */
    /* background-image: url(https://d3c5t20k88187g.cloudfront.net/productpage.parim.co.uk/loginpage/images/bg_blue.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
}


.loginView__sideArea__vr {
  bottom: 10px;
  font-size: 11px;
  left: 30px;
    position: absolute;
    width: 200px;
    clear: both;
}

.loginView__sideArea__vr  a { color: #dadfe1; }


/*titles*/

.loginView__loginArea__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.loginView__loginArea__title, .loginView__loginArea__st { 
     color: #3B4C53;
}

.loginView__loginArea__st h3 {
    font-size: 18px;
    line-height: 36px;
    font-weight: 600;
    margin-top: 10px;
}

.loginView__loginArea__qt {
    color: #879099;
    text-align: center;
    font-size: 14px;
}

@media (min-width:768px) {
    .loginView__loginArea__title {
        font-size: 30px;
        line-height: 36px
    }
    .loginView__loginArea__st h3 {
        margin-top: 30px;
    }
    .loginView__loginArea__qt {
          margin: 10px 0 20px;
    }
}



/**form**/

@media (min-width:1024px) {
    .loginForm, .unsubscribeForm, #messages, #page-messages {
        width: 368px;
        margin: 0 auto;
    }
}

.loginView__loginArea__tw>:last-child {
    margin-bottom: 0
}

.loginView__loginArea__tw+* {
    margin-top: 44px
}

@media (min-width:768px) {
    .loginView__loginArea__tw+* {
        margin-top: 64px
    }
}

.row  {
    position: relative;
    margin-bottom: 12px
}

label {
 color: #879099;
 font-size: 12px;
 letter-spacing: 0.24px;
 font-family: 'Inter';
 text-transform: uppercase;
}

label .required { display: none; }

input[type="text"], input[type="password"] {
    -moz-box-sizing: border-box;
    box-sizing:border-box;
    background-color: #FFFFFF;
    border: 1px solid #C3CFD5; 
    color: #0B0C0D;
    font-size: 15px;
    padding: 14px;
    width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

::-webkit-input-placeholder { /* Edge */
  color: #a4b3ba;
}

:-ms-input-placeholder { /* Internet Explorer */
  color: #a4b3ba;
}

::placeholder {
  color: #a4b3ba;
}

.error input[type="text"], .error input[type="password"],
input[type="text"].error, input[type="password"].error {
  background-color: #Ffffff;
  border-color:#CB655F;
  outline-color: #CB655F;
}

.totp-code-row {
	margin-top:10px;
}

input[type="submit"],
button[type="submit"],
.unsubscribeForm input[type="button"] {
    -moz-box-sizing: border-box;
    box-sizing:border-box;
    padding: 14px;
    background-color: #2D9CDB;
    background-image:url(https://d3c5t20k88187g.cloudfront.net/productpage.parim.co.uk/loginpage/images/login-loader.gif);
    background-repeat: no-repeat;
    background-position: -100px 50%;
    width: 100%;
    border:none;
    font-size: 17px;
    color: #FFF;
    cursor: pointer;
    -webkit-border-radius:3px;
       -moz-border-radius:3px;
            border-radius:3px;
}

.unsubscribeForm input[type="button"] {
    background-color: #36b259;
    padding: 10px;
    max-width: 240px;
    font-size: 14px;
}

#login-form button[type="submit"]:hover{
  background-color: #2c8fc7;
}

#login-form button[type="submit"].loading{
  background-position: 50% 50%;
  background-color: #8eb5e2;
  text-indent: -999px;
}

#login-form button[type="submit"]:disabled{
  background-color: #91BCD9;
  cursor: not-allowed;
}

#login-form button[type="submit"].loading > img {
  display: none;
}

#login-form button[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

#send-totp-code-btn {
    -moz-box-sizing: border-box;
    box-sizing:border-box;
    padding: 14px;    
    color: #37474f;
    background-color: #fff;
    background-image:url(https://d3c5t20k88187g.cloudfront.net/productpage.parim.co.uk/loginpage/images/login-loader.gif);
    background-repeat: no-repeat;
    background-position: -100px 50%;
    width: 100%;
    border: 1px solid #c3cfd5;
    font-size: 14px;    
    cursor: pointer;
    -webkit-border-radius:3px;
       -moz-border-radius:3px;
            border-radius:3px;
}

#send-totp-code-btn:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

#send-totp-code-btn:hover{  
  background-color: #e6e6e6;
  border-color: #9fb2bc;
}

#send-totp-code-btn:hover:disabled{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.info{ padding: 0 3px; text-align: right; margin: 12px 0}


.logo  {
   text-align: center;
}

.logo img {
    max-height: 70px;
    max-width: 300px;
}

#HelpMessage {
    display: inline-block;
    border-top: 1px solid rgba(0,0,0,.2);
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 12px;
    width: 200px;
    color: #828282;
}
.LangSwitcher{
    border: 1px solid #C3CFD5;
    color: #3B4C53;
    font-size: 12px;
    padding: 2px;
    margin-top:5px;
}

.fl {
    float: left;
}

.hide {
    display: none;
}

/***************** Notificatiobn BOXES *****************************************/

.information-box, .confirmation-box, .error-box, .warning-box {
  -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 15px;
  margin-bottom:12px;
}

.information-box {
  background: #D9EDF7;
  border-color: #BCE8F1;
  color: #5A9BAB;
}

.confirmation-box {
  background-color: #DFF0D8;
  border-color: #D6E9C6;
  color: #3C763D;
}

.error-box {
  background-color: #F2DEDE;
  border-color: #EBCCD1;
  color: #A94442;
}

.warning-box {
  background-color: #FCF8E3;
  border-color: #FAEBCC;
  color: #8A6D3B;
}


#messages {
  margin: 6px 0;
  color: #CB655F;
  display: block;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 22.4px;
}

#messages .page-alert {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

#messages .page-info, #capsalert .page-info {
  background-color:  #F1C40F;
  color: #FFFFFF;
    -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

#messages a {
  color: #CB655F;
  text-decoration: underline;
}